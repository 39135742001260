import styled  from 'styled-components'

export const Main = styled.main`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  row-gap: 60px;
  width: 100%;
`

export const Pagination = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

